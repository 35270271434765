import { Route, Routes } from "react-router-dom";
import Products from "../pages/Products";

function ProductsRoute() {
  
    return (
      <>
      <Routes>
        <Route exact path="/products"
        element={
          <Products />
        }
        />
      </Routes>
      </>
    );
  }
  
  export default ProductsRoute;