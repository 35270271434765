import SectionOne from '../components/landingPage/SectionOne'
import SectionTwo from '../components/landingPage/SectionTwo'
import SectionThree from '../components/landingPage/SectionThree'
import SectionFour from '../components/landingPage/SectionFour'

function LandingPage() {
  return (
    <>
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
    </>
  )
}

export default LandingPage