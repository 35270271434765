import { styled } from "@mui/material/styles";
import TelegramIcon from "@mui/icons-material/Telegram";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

const CustomStyles = {

    FooterWrapper: styled("div")(({ theme }) => ({
        backgroundColor: theme.palette.primary.light100,
    })),
    
      NewsLetterArea: styled("div")(({ theme }) => ({
        backgroundColor: theme.palette.primary.light300,
        padding: "30px 0px",
      })),
    
      NewsLetterinnerWrapper: styled("div")(({ theme }) => ({
        maxWidth: "1200px",
        margin: "auto",
        padding: "0px 20px",
      })),
    
      SubscribeIcon: styled(TelegramIcon)(({ theme }) => ({
        fontSize: "50px",
        color: "#4d4d4d",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10vw",
        },
    })),
    
      SubscribeText: styled(Typography)(({ theme }) => ({
        color: theme.palette.secondary.dark,
      })),
    
        Search: styled("div")(({ theme }) => ({
        maxWidth: "450px",
        padding: "0px 2px 0px 6px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
      })),
    
      SubscribeButton: styled(Button)(({ theme }) => ({
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        display: "flex",
        wrap: "nowrap",
        padding: theme.spacing(1, 3),
        height: "100%",
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        boxShadow: theme.shadows[2],
      })),
    
      InputRoot: styled(InputBase)(({ theme }) => ({
        color: "#424242",
        width: "100%",
        padding: theme.spacing(0.7, 3, 0.7, 1),
      })),
    
      MainFooter: styled(Container)(({ theme }) => ({
        paddingTop: "20px",
      })),
    
      WrapMainFooter: styled("div")(({ theme }) => ({
        padding: "0px 20px",
      })),
    
      Image: styled("img")(({ theme }) => ({
        width: "70%",
        height: "auto",
        objectFit: 'contain'
      })),
    
      ContactText: styled("div")(({ theme }) => ({
        color: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.primary.main,
        },
      })),
    
      FooterBase: styled("div")(({ theme }) => ({
        borderTop: "1px solid lightgrey",
        backgroundColor: theme.palette.primary.light200,
        [theme.breakpoints.up("sm")]: {
            borderTop: "none",
        }
      })),
    
      InnerFooterBase: styled("div")(({ theme }) => ({
        padding: "20px",
        textAlign: "center",
      }))

}

export default CustomStyles