import React from 'react'
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Carousel from 'react-material-ui-carousel'
import CustomStyles from '../../styles/landingPage/SectionFour';

function SectionFour() {
  return (
    <CustomStyles.SectionWrapper>
      <CustomStyles.Section>
        {/* Testimonial area */}
        <CustomStyles.TextArea>
        <CustomStyles.TextContainer>
          <CustomStyles.QuoteImage src="/images/quote.png" alt="quote" />
          <Typography variant='h5' fontWeight={700} sx={{mt: 3, mb: 3}}>
            What our customers say.
            </Typography>

            <Carousel
      //indicators={false}
      navButtonsAlwaysInvisible={true}
      height={200}
      >
            {
                [0,1,2,3,4].map( (item, i) => 
                (
                  <div key={i}>
                  <CustomStyles.UserDetail>
                  <CustomStyles.RoundImageContainer>
                  <CustomStyles.CoverImage src="/images/melanin.jpg" alt="fast delivery" />
                  </CustomStyles.RoundImageContainer>
        
                  <CustomStyles.UserTextArea>
                  <Typography variant='h6' fontWeight={700} color="dark" lineHeight={1.8}>
                    Melanin Angela
                    </Typography>
                  <Typography variant='body2' fontWeight={500} color="secondary">
                   Food Distributor
                    </Typography>
                  </CustomStyles.UserTextArea>
                </CustomStyles.UserDetail>
        
                <CustomStyles.ReviewTextCont>
                <CustomStyles.ReviewText variant='body2' fontWeight={400} color="secondary">
                   Foodlane is super good at what they do, their food product quality is topnotch. As a distributor, I have constantly received possitive feedbacks from my customers.
                    </CustomStyles.ReviewText>
                    </CustomStyles.ReviewTextCont>
                    <Stack spacing={1} flexDirection="row" alignItems="center">
                    <Rating name="size-small" defaultValue={4} readOnly /> 
                    <span style={{margin: "0px 0px -2px 5px", fontWeight: 500}}>4.5</span>
                    </Stack>
                    </div> 
                )
                )
            }
        </Carousel>

        </CustomStyles.TextContainer>
        </CustomStyles.TextArea>

      {/* Image Area */}
      <CustomStyles.ImageContainer sx={{display: {xs: "none", md: "block"}}}>
          <CustomStyles.Image src={"/images/feedback.png"} alt={"Testimonials"}/>
        </CustomStyles.ImageContainer>
      </CustomStyles.Section>
    </CustomStyles.SectionWrapper>
  )
}

export default SectionFour