import React from 'react'
import List from "@mui/material/List"
import { Grid, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "4px",
  }))

  const CustomListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }))

function FindInFast() {
    const categories = [
        {
          text: "African soup",
          link: "african-soup",
        },
        {
          text: "Natural flour",
          link: "natural-flour",
        },
        {
          text: "Natural grains",
          link: "natural-grains",
        },
        {
          text: "Natural spices",
          link: "natural-spices",
        },
        {
          text: "Organic drinks",
          link: "organic-drinks",
        }
      ];
      const quickLinks = [
        {
          text: "About Us",
          link: "about_us",
        },
        {
          text: "Sign Up",
          link: "signup",
        },
        {
          text: "Bulk Purchase",
          link: "bulk_purchase",
        },
        {
          text: "Preorder",
          link: "preorder",
        },
        {
          text: "Shipping policy",
          link: "shipping",
        },
      ];
      
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6} md={3}>
              <List>
                <Title variant="subtitle2">
                  TOP LINKS
                </Title>
                {quickLinks.map((quickLink) => (
                  <CustomListItem
                    key={quickLink.link}
                    className={`routerLink`}
                    component={Link}
                    to={`/`}
                  >
                    <ListItemText primary={quickLink.text} />
                  </CustomListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <List>
                <Title variant="subtitle2">
                  CATEGORIES
                </Title>
                {categories.map((category) => (
                  <CustomListItem
                    key={category.link}
                    className={`routerLink`}
                    component={Link}
                    to={`/`}
                  >
                    <ListItemText primary={category.text} />
                  </CustomListItem>
                ))}
              </List>
            </Grid>

        </React.Fragment>
    )
}

export default FindInFast
