import React from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Carousel from 'react-material-ui-carousel'
import CustomStyles from '../../styles/landingPage/SectionOne';
import { Link } from "react-router-dom"


function SectionOne() {
  const xsScreen = useMediaQuery('(max-width:450px)');
  const smScreen = useMediaQuery('(max-width:600px)');

  const carouselItems = [
    {
        name: "Carousel Image 1",
        image: "/images/plantainflour1.png"
    },
  {
    name: "Carousel Image 4",
    image: "/images/poundedyam3.png"
}
]

  return (
    <CustomStyles.SectionWrapper>
    <CustomStyles.Section>
      <Grid container alignItems="center" flexWrap="wrap" justifyContent="space-between">
        {/* text area */}
      <Grid item xs={12} md={5}>
        <CustomStyles.BgText>
        <Typography variant="caption" fontWeight={700} color="primary">NATURAL FOODS</Typography>
        </CustomStyles.BgText>
        <Typography variant="h2" fontWeight={700} color="dark">Your easy access to quality foods</Typography>
        <Typography sx={{mt: 2}} fontWeight={400} variant="body2" color="secondary">Food Lane is a food processing and marketing company dedicated to improving lives through innovative food technology and agricultural food chain value addition. We are positioned to ensure quality foods are accessible and affordable</Typography>
        {/* button area */}
        <Grid 
        container 
        alignItems={"center"}
        sx={{mt: 3}}
        >
          <CustomStyles.ContainedButton 
          disableElevation variant='contained' sx={{mr: 3}}
          component={Link}
          to="/products"
          >
            Order Now
          </CustomStyles.ContainedButton>
          <CustomStyles.OutlinedButton 
          variant='outlined'
          component={Link}
          to="/products"
          >
            Get the food
            </CustomStyles.OutlinedButton>
        </Grid>

      </Grid>
      {/* Image area */}
      
      <CustomStyles.ImageContainer item xs={12} md={7} sx={{mt: {xs: 7, md: 0}}}>
      <Carousel
      indicators={false}
      navButtonsAlwaysInvisible={true}
      height={xsScreen ? 300 : smScreen ? 400 : 500}
      >
            {
                carouselItems.map( (item, i) => <CustomStyles.Image key={i} src={item.image} alt={item.name} /> )
            }
        </Carousel>
      </CustomStyles.ImageContainer>

      </Grid>

    </CustomStyles.Section>
    </CustomStyles.SectionWrapper>
  )
}
 
export default SectionOne