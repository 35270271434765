import { Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ContactSales from "../pages/ContactSales";

function General() {
  
    return (
      <>
      <Routes>
        <Route exact path="/"
        element={
          <LandingPage />
        }
        />
        <Route exact path="/contact-sales"
        element={
          <ContactSales />
        }
        />
      </Routes>
      </>
    );
  }
  
  export default General;