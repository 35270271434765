import { styled } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

const CustomStyles = {
    SectionWrapper: styled("div")(({ theme }) => ({
        padding: "50px 0px",
        background: theme.palette.primary.light200,
        [theme.breakpoints.up("md")]: { 
          padding: "70px 0px",
      },
      })),
    
      Section: styled(Container)(({ theme }) => ({
        [theme.breakpoints.up("md")]: { 
          display: "flex",
          alignItems: 'center',
      },
      })),
    
      ImageContainer: styled(Grid)(({theme }) => ({
        width: "45%",
        padding: 0,
        [theme.breakpoints.up("md")]: { 
          padding: "0px 20px",
        },
      })),
      
      Image: styled("img")({
        width: "100%",
        height: "auto",
        objectFit: "contain"
      }),
    
      TextArea: styled("div")(({theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.up("md")]: { 
          width: "55%",
        },
      })),
    
      TextContainer: styled("div")(({theme }) => ({
        maxWidth: "450px",
        margin: 'auto',
      })),
    
      EachCard: styled("div")(({theme }) => ({
        marginTop: 20,
        display: 'flex',
        alignItems: "center",
        width: "100%",
        background: "#ffffff",
        borderRadius: theme.shape["borderRadius"] * 1.5,
        //boxShadow: "0px 3px 5px 3px rgba(0,0,0,0.3)"
        boxShadow: theme.shadows["6"],
        padding: 15
      })),
    
      BoxImageContainer: styled("div")(({theme }) => ({
        background: "#ffffff",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        height: 50,
        borderRadius: theme.shape["borderRadius"] * 1.5,
        //boxShadow: "0px 3px 5px 3px rgba(0,0,0,0.3)"
        boxShadow: theme.shadows["3"],
        padding: 10
      })),
    
      CardTextArea: styled("div")(({theme }) => ({
        paddingLeft: 20
      }))
}

export default CustomStyles