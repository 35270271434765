import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ProductStyles from "../product/AllProducts";

const { 
  ProductCard, ProductTopSection, ProductImage,
  ProductBottomSection, CartWrapper, CartBackground,
  CartContainer
} = ProductStyles

const CustomStyles = {
    SectionWrapper: styled("div")(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        padding: "50px 0px",
        background: theme.palette.primary.light200,
        [theme.breakpoints.up("md")]: { 
          padding: "70px 0px",
      },
      })),
    
      ScrollingButton: styled(Button)(({theme }) => ({
        minWidth: 130,
        background: theme.palette.primary.light200,
        borderRadius: theme.shape.borderRadius * 1.5,
        textTransform: "initial",
        whiteSpace: "nowrap",
        "&:first-of-type": {
            marginLeft: "15px"
        },
        "&:last-of-type": {
            marginRight: "15px"
        },
        margin: "0px 5px"
      })),
    
      Section: styled(Container)(({ theme }) => ({
        marginTop: 200,
        height: 220,
        position: "relative",
        [theme.breakpoints.up("md")]: { 
        //   padding: "70px 0px",
      },
      })),
    
      SubSection: styled("div")(({ theme }) => ({
        height: "100%",
        background: theme.palette.primary.light300,
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.up("md")]: { 
        //   padding: "70px 0px",
      },
      })),
    
      ProductContainer: styled(Grid)(({ theme }) => ({ 
        position: "absolute",
        overflowX: "auto",
        top: -185,
        left: 0, 
        padding: "50px 20px 0px",
        justifyContent: "flex-start", 
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        [theme.breakpoints.up(1000)]: { 
        //   padding: "70px 0px",
        justifyContent: "center"
      },
      })),
      
  ProductCard, ProductTopSection, ProductImage,
  ProductBottomSection, CartWrapper, CartBackground,
  CartContainer
      
}

export default CustomStyles