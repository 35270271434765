import React, { useEffect, useState } from "react";
import { Grid, IconButton, Divider, Hidden } from "@mui/material";
import Typography from "@mui/material/Typography";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from '@mui/icons-material/Twitter';
import { loadCSS } from "fg-loadcss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GoogleIcon from '@mui/icons-material/Google';
import FindInFast from "./FindInFast";
import { useTheme } from "@mui/material/styles";
import CustomStyles from "../../styles/general/Footer";

function Footer() {
  const currentYear = new Date().getFullYear();
  const [formInput, setFormInput] = useState({email: ''})
  const [errors, setErrors] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const inputHandler = (e) => {
    setErrors(null)
    setFormInput({email: e.target.value.trim()})
  }
  const handleSubscribe = (e) => {
    e.preventDefault()
  }

  return (
    <CustomStyles.FooterWrapper>
      <CustomStyles.NewsLetterArea>
        <CustomStyles.NewsLetterinnerWrapper>
          <Grid container justify="center" spacing={2} alignItems="center">
            <Grid
              item
              container
              xs={12}
              sm={6}
              spacing={2}
              sx={{paddingTop: {xs: 0}, display: {xs: "none", sm: "block"}}}
            >
              <Hidden mdUp>
              <Grid item xs={2}>
                
                <IconButton style={{ padding: 0 }}>
                  <CustomStyles.SubscribeIcon />
                </IconButton>
              </Grid> 
              </Hidden>
              <Grid item xs={10} container sx={{marginLeft: {xs: -15, sm: 0}}}>
                <Grid item xs={12}>
                  <CustomStyles.SubscribeText variant="h5" fontWeight={500}>
                    Subscribe to our newsletter.
                  </CustomStyles.SubscribeText>
                </Grid>
                <Grid item xs={12}>
                  <CustomStyles.SubscribeText
                    variant="body2"
                    sx={{mt: 1}}
                  >
                    Get updates on latest products and offers
                  </CustomStyles.SubscribeText>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomStyles.Search>
                <CustomStyles.InputRoot
                  placeholder="Enter your email address"
                  inputProps={{ "aria-label": "search" }}
                  value={formInput.email}
                  onChange={inputHandler}
                />
                <CustomStyles.SubscribeButton
                  style={{ boxShadow: "none" }}
                  onClick={handleSubscribe}
                >
                  <Typography variant="body2"> Subscribe</Typography>
                </CustomStyles.SubscribeButton>
              </CustomStyles.Search>
              {
                   errors &&
                  <Typography>{errors.email}</Typography>
                  }
            </Grid>
            {/* </Grid> */}
          </Grid>
        </CustomStyles.NewsLetterinnerWrapper>
      </CustomStyles.NewsLetterArea>
      <CustomStyles.MainFooter>
        <CustomStyles.WrapMainFooter>
          <Grid container justifyContent="space-between" spacing={5}>
            <Grid container item xs={12} sm={6} md={3}>
              <Hidden smDown>
                <Grid item xs={12}>
                  <div>
                    <CustomStyles.Image src="/images/foodlane.png" alt="FoodLane logo" />
                  </div>
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <CustomStyles.SubscribeText
                sx={{mt:1}}
                  variant="subtitle2"
                >
                  Reach us, We are always available.
                </CustomStyles.SubscribeText>
                <CustomStyles.ContactText variant="h6">
                  <IconButton color="primary" sx={{paddingLeft: 0}}>
                    <CallIcon />
                  </IconButton>
                  <a className="routerLink" href="tel: +2349073357746">
                  09073357746
                  </a>
                </CustomStyles.ContactText>
              </Grid>
              <Grid item xs={12}>
                <CustomStyles.ContactText variant="h6">
                  <IconButton color="primary" sx={{paddingLeft: 0}}>
                    <WhatsAppIcon />
                  </IconButton>
                  <a className="routerLink" 
                  href="https://wa.me/+2349060305354"
                  target="_blank"
                  rel="noreferrer"
                  >
                  09060305354
                  </a>
                </CustomStyles.ContactText>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <IconButton sx={{paddingLeft: 0}}>
                    <a 
                    href="https://www.facebook.com/FoodlaneNg" 
                    className="routerLink"
                    target="_blank"
                    rel="noreferrer"
                    >
                      <FacebookIcon sx={{fontSize: "30px", color: "#000000",}} />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      href="https://me.goog/foodlane"
                      className="routerLink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GoogleIcon sx={{fontSize: "30px", color: "#000000",}} />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      href="https://twitter.com/lane_food"
                      className="routerLink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon sx={{fontSize: "30px", color: "#000000",}} />
                    </a>
                  </IconButton>
                  <IconButton sx={{height: 45, width: 45}}>
                    <a 
                    href="https://flutterwave.com/store/foodlaneng" 
                    className="routerLink"
                    target="_blank"
                    rel="noreferrer"
                    >
                    <CustomStyles.Image 
                    style={{width: '100%'}} 
                    src="/images/flutterwave-logo.png" 
                    alt="Flutterwave logo" 
                    />
                    </a>
                  </IconButton>
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{paddingTop: "10px", fontWeight: 600,}}>
                <span style={{color: theme.palette.primary.dark}}>Factory Address:</span> No 66, Line 3, Zone 2, Oke-Alafia Estate, Owode Ede, Osun State
                </Typography>
                <Typography variant="subtitle2" sx={{paddingTop: "10px", fontWeight: 600,}}>
                  <span style={{color: theme.palette.primary.dark}}>Osogbo Sales outlet:</span> B-18 omoluabi shopping plaza, Old garage Area, Osogbo, Osun state
                </Typography>
                <div style={{height: 10}}></div>
                {/* <a 
                href="/#"
                className="coloredLink"
                >
                  View on map
                </a> */}
                <div style={{height: 20}}></div>
              </Grid>
            </Grid>
            <Hidden smDown>
              <FindInFast />
            </Hidden>
          </Grid>
        </CustomStyles.WrapMainFooter>
      </CustomStyles.MainFooter>

      <Hidden smUp>
        <Grid item xs={12} md={9} style={{ marginTop: 30 }}>
          <Accordion elevation={0} style={{borderRadius: 0, backgroundColor: theme.palette.primary.light200}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="quick-actions"
              id="quick-header"
            >
              <Typography variant="h5" style={{fontWeight: 'bold'}}>
                Quick Actions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <FindInFast />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Hidden>

      <CustomStyles.FooterBase>
        <div>
          <CustomStyles.InnerFooterBase>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">
                  &copy;{currentYear} <strong>FoodLane.</strong> All Rights Reserved
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <IconButton
                  className="fab fa-cc-visa"
                  style={{
                    fontSize: 30,
                    color: "#000000",
                    padding: "0px 8px 0px 0px",
                  }}
                />
                <IconButton
                  className="fab fa-cc-mastercard"
                  style={{
                    fontSize: 30,
                    color: "#000000",
                    padding: "0px 8px 0px 0px",
                  }}
                />
                <IconButton
                  className="fas fa-credit-card "
                  style={{
                    fontSize: 30,
                    color: "#000000",
                    padding: 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" sx={{fontWeight: 700}}>
                <a className="routerLink" href="https://wa.me/+2348169288142">
                Developed by Mikesoft Resources
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </CustomStyles.InnerFooterBase>
        </div>
      </CustomStyles.FooterBase>
    </CustomStyles.FooterWrapper>
  );
}

export default Footer;
