import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./utils/theme";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/general/Navbar";
import Footer from "./components/general/Footer";
import General from "./routes/General";
import Products from "./routes/Products";
import StartFromPageTop from "./components/general/StartFromPageTop";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <StartFromPageTop />
        <>
        <Navbar/>
       <General />
       <Products />
       <Footer />
       </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
