import { styled } from "@mui/material/styles";
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

const CustomStyles = {
    AppBarWrapper: styled(Container)(({ theme }) => ({
        width: "100%",
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: 'center',
        [theme.breakpoints.down(910)]: { 
          padding: "10px 0",
      },
      })),
      
     AppbarLink: styled(NavLink)(({ theme }) => ({
       color: theme.palette.secondary.main,
       textTransform: "capitalize",
       padding: "7px 0px",
       "&:not(:last-of-type)": {
        marginRight: 25
       }
      })),
      
    AppLinkText: styled(Typography)(({theme}) => ({
        display: "inline-block", 
        fontWeight: 400,
        "&:hover": {
          fontWeight: 500
        }
      })),
      
     Logo: styled("img")(({ theme }) => ({
        height: "30px",
        width: "auto",
        objectFit: "contain",
        [theme.breakpoints.up("md")]: {
          height: "35px",
        }
       })),
      
     WrapperLink: styled(NavLink)({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       }),
      
     MainButton: styled(Button)(({ theme }) => ({
        padding: "7px 15px",
        background: theme.palette.primary.main,
        color: "#ffffff",
        textTransform: "capitalize",
        borderRadius: theme.shape["borderRadius"] * 1.5,
        "&:hover": {
          background: theme.palette.primary.dark,
        }
       })),
      
      //   OutlinedButton: styled(Button)(({ theme }) => ({
      //   padding: "5px 13px",
      //   display: "none",
      //   alignItems: "center",
      //   background: "none",
      //   color: theme.palette.primary.main,
      //   border: `1px solid ${theme.palette.primary.main}`,
      //   textTransform: "capitalize",
      //   "&:hover": {
      //     background: theme.palette.primary.light200,
      //   },
      //   [theme.breakpoints.up("sm")]: {
      //     display: "inline-flex",
      //   }
      //  }))
}

export default CustomStyles