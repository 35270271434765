import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

const CustomStyles = {

    SectionWrapper: styled("div")(({ theme }) => ({
        padding: "50px 0px",
        background: theme.palette.primary.light100,
        [theme.breakpoints.up("md")]: { 
          padding: "50px 0px 60px",
      },
      })),
      
      Section: styled(Container)(({ theme }) => ({
        display: "flex",
        alignItems: 'center'
      })),

    ProductCard: styled(Paper)(({ theme }) => ({
        minWidth: 200,
        maxWidth: 200,
        margin: "10px 0px",
        display: "flex",
        padding: "15px 20px",
        flexDirection: "column",
        height: 250,
        cursor: "pointer",
        background: "#ffffff",
        "&:not(:last-of-type)": {
           marginRight: "20px",
        },
        "&:first-of-type": {
            marginLeft: "20px",
        },
        "&:last-of-type": {
            marginRight: "20px",
        },
        "&:hover": {
            boxShadow: "0px 3px 5px 3px rgba(0,0,0,0.3)"
        },
        [theme.breakpoints.up(1000)]: { 
            "&:not(:last-of-type)": {
                marginRight: "50px",
             },
      },
      })),
    
      ProductTopSection: styled("div")(({ theme }) => ({
        background: theme.palette.primary.light200,
        borderRadius: theme.shape.borderRadius,
        height: 140,
        position: 'relative',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center"
      })),
    
      ProductImage: styled("img")(({ theme }) => ({
        display: 'block',
        position: "absolute",
        zIndex: 10,
        bottom: 5,
        // left: 0,
        objectFit: "contain",
        //height: '110%',
        height: 'auto',
        width: '90%'
      })),
    
      ProductBottomSection: styled("div")(({ theme }) => ({
        paddingTop: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 80
      })),
    
      CartWrapper: styled("div")(({ theme }) => ({
        position: 'relative',
      })),
    
      CartBackground: styled("div")(({ theme }) => ({
        position: "absolute",
        zIndex: 0,
        background: "#ffffff",
        bottom: -5,
        right: -5,
        borderRadius: "50%",
        height: 17,
        width: 17,
      })),
    
      CartContainer: styled("div")(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
       padding: "5px 8px",
       borderRadius: theme.shape.borderRadius * 2,
       background: theme.palette.primary.main
      }))
}

export default CustomStyles