import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const CustomStyles = {

    SectionWrapper: styled("div")(({ theme }) => ({
        padding: "50px 0px",
        background: theme.palette.primary.light200,
        [theme.breakpoints.up("md")]: { 
          padding: "70px 0px",
      },
      })),
      
      Section: styled(Container)(({ theme }) => ({
        [theme.breakpoints.up("md")]: { 
          display: "flex",
          alignItems: 'center',
      },
      })),
      
      ImageContainer: styled(Grid)(({theme }) => ({
        width: "50%",
        padding: 0,
        [theme.breakpoints.up("md")]: { 
          // padding: "0px 20px",
        },
      })),
      
      Image: styled("img")({
        width: "100%",
        height: "auto",
        objectFit: "contain"
      }),
      
      QuoteImage: styled("img")({
        width: 70,
        height: "auto",
        objectFit: "contain"
      }),
      
      CoverImage: styled("img")({
        width: "auto",
        height: "100%",
        objectFit: "cover"
      }),
      
      TextArea: styled("div")(({theme }) => ({
        display: "flex",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.up("md")]: { 
          width: "50%",
        },
      })),
      
      TextContainer: styled("div")(({theme }) => ({
        width: "100%",
      })),
      
      ReviewTextCont: styled("div")(({theme }) => ({
        height: 50,
        marginBottom: 20,
        [theme.breakpoints.up(1080)]: { 
        marginBottom: 10,
        },
      })),
      
      ReviewText: styled(Typography)(({theme }) => ({
        overflow: "hidden",
        display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical"
      })),
      
      UserDetail: styled("div")(({theme }) => ({
        display: 'flex',
        alignItems: "center",
        width: "100%",
        marginBottom: 20
      })),
      
      RoundImageContainer: styled("div")(({theme }) => ({
        overflow: 'hidden',
        width: 75,
        height: 75,
        borderRadius: "100%",
        boxShadow: theme.shadows["1"],
      })),
      
      UserTextArea: styled("div")(({theme }) => ({
        paddingLeft: 20
      }))

}

export default CustomStyles