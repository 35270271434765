import React from 'react'
import Typography from '@mui/material/Typography';
import CustomStyles from '../../styles/landingPage/SectionThree';

function SectionThree() {
  
  const listItems = [
    {
      image: "/images/qualityproduct.png",
      title: "Top notch quality",
      text: "Our food products contains high nutritional values"
    },
    {
      image: "/images/easyorder.png",
      title: "Order your product anytime",
      text: "No matter when you order, we will process and deliver it"
    },
    {
      image: "/images/fastdelivery.png",
      title: "Best delivery service",
      text: "No matter where you are, We will deliver in time"
    }
  ]

  return (
    <CustomStyles.SectionWrapper>
      <CustomStyles.Section>
        <CustomStyles.ImageContainer sx={{display: {xs: "none", md: "block"}}}>
          <CustomStyles.Image src={"/images/whychooseus.png"} alt={"Why choose us"}/>
        </CustomStyles.ImageContainer>
        {/* Text area */}
        <CustomStyles.TextArea>
          <CustomStyles.TextContainer>
        <Typography variant="h4" fontWeight={700} color="dark">
          Why You Should Choose Us?
        </Typography>
        <Typography sx={{mt: 2, mb: 4}} fontWeight={400} variant="body2" color="secondary">
        We are dedicated to improving lives through innovative food technology and agricultural food chain value addition
        </Typography>
        {/* cards */}
        {listItems.map((item, i) => (<CustomStyles.EachCard key={i}>
          <CustomStyles.BoxImageContainer>
          <CustomStyles.Image src={item.image} alt="fast delivery" />
          </CustomStyles.BoxImageContainer>

          <CustomStyles.CardTextArea>
          <Typography variant='subtitle1' fontWeight={700} color="dark" lineHeight={1.5}>
            {item.title}
            </Typography>
          <Typography variant='caption' fontWeight={500} color="secondary">
            {item.text}
            </Typography>
          </CustomStyles.CardTextArea>
        </CustomStyles.EachCard>))
        }
      
        </CustomStyles.TextContainer>
        </CustomStyles.TextArea>

      </CustomStyles.Section>
      
    </CustomStyles.SectionWrapper>
  )
}

export default SectionThree