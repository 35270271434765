import React from 'react'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CustomStyles from '../styles/product/AllProducts'
import FooterStyles from '../styles/general/Footer'

function ContactSales() {
  return (
    <CustomStyles.SectionWrapper>
    <CustomStyles.Section style={{maxWidth: "600px"}}>
    <Paper elevation={5} sx={{p: 3, my: 5}}>
        <Typography color="secondary.dark" variant='h6' fontWeight={400} sx={{mb: 2}}>Please click the <strong>WhatsApp number</strong> below to order any product of choice. We are currently working towards automating the order, <strong>kindly bear with us.</strong></Typography>
        <Grid item xs={12}>
                <FooterStyles.ContactText variant="h6">
                  <IconButton color="primary" sx={{pl: 0, mt: -0.5}}>
                    <WhatsAppIcon />
                  </IconButton>
                  <a className="routerLink" target="_blank" rel="noreferrer" href="https://wa.me/+2349060305354">
                    <strong>09060305354</strong>
                  </a>
                </FooterStyles.ContactText>
              </Grid>
              </Paper>
        </CustomStyles.Section>
        </CustomStyles.SectionWrapper>
  )
}

export default ContactSales