import { responsiveFontSizes, createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#34B845",
      medium: "#7dda89",
      light: "#8fdf99",
      light300: "#a8e6b0",
      light200: "#e7f8e9",
      light100: "#f7fdf8"
    },
    secondary: {
      main: "#595959"
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
