import React from 'react'
import { useTheme } from "@mui/material/styles";
import Typography  from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
//import useMediaQuery from '@mui/material/useMediaQuery';
// import Rating from '@mui/material/Rating';
// import Stack from '@mui/material/Stack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CustomStyles from '../../styles/landingPage/SectionTwo';
import { Link } from 'react-router-dom';

function SectionTwo() {
    const theme = useTheme()
    // const mdScreen = useMediaQuery('(min-width:700px)');
    // const [currentCategory, setCurrentCategory] = useState("natural-flour")

    // const handleSetCategory = (endpoint) => {
    //     setCurrentCategory(endpoint)
    // }

    // const btnItems = [
    //     {
    //         endpoint: "natural-flour",
    //         text: "Natural flour"
    //     },
    //     {
    //         endpoint: "natural-spices",
    //         text: "Natural spices"
    //     }
    // ]

    const products = [
        {
            name: "Yam flour (2kg)",
            image: "/images/yamflour2kg.png"
        },
        {
            name: "Pepper powder (70g)",
            image: "/images/pepperpowder.png"
        },
        {
            name: "Plantain flour (1kg)",
            image: "/images/plantainflour.png"
        },
        {
            name: "Yam flour (1kg)",
            image: "/images/yamflour1kg.png"
        }
    ]

  return (
    <CustomStyles.SectionWrapper>
        <Container>
            <Typography variant='h4' fontWeight={700} color="dark" sx={{mb: 2}} textAlign="center">
        Popular food products
        </Typography>
        </Container>
    {/* <Grid 
    container 
    alignItems="center" 
    justifyContent={mdScreen ? "center" : "flex-start"}
    sx={{mt: 2, overflowX: 'auto', py: 2}} 
    flexWrap="nowrap"
    >
    {
    btnItems.map(item => {
         const isCurrentItem = currentCategory === item.endpoint
    return (
    <CustomStyles.ScrollingButton 
    onClick={()=>handleSetCategory(item.endpoint)}
    key={item.endpoint}
    sx={{
        borderWidth: isCurrentItem ? "none" : "2px",
        color: isCurrentItem ? "#ffffff" : theme.palette.secondary.dark,
        background: isCurrentItem ? theme.palette.primary["main"] : "none",
        "&:hover": {
            background: theme.palette.primary[isCurrentItem ? "dark" : "light200"],
        },
    }}
    variant={"contained"}
    >
        {item.text}
    </CustomStyles.ScrollingButton>)
    })
    }
    </Grid> */}
    {/* products area */}
    <CustomStyles.Section>
    <CustomStyles.SubSection>
        <CustomStyles.ProductContainer 
        container 
        flexWrap="nowrap" 
        >

            {products.map((item, i) => (
            <CustomStyles.ProductCard 
            key={i} 
            elevation={5}
            component={Link}
            to="/contact-sales"
            className="routerLink"
            >
                <CustomStyles.ProductTopSection>
                <CustomStyles.ProductImage src={item.image} alt={item.name} />
                </CustomStyles.ProductTopSection>
                {/* bottom section */}
                <CustomStyles.ProductBottomSection>
                    <div>
                    <Typography variant='subtitle2'>
                        {item.name}
                    </Typography>
                    {/* ratings */}
                    {/* <Stack spacing={1}>
                    <Rating name="size-small" defaultValue={4} size="small" readOnly />
                    </Stack> */}
                    </div>
                    {/* Amount area */}
                    <Grid container justifyContent="space-between" alignItems="flex-end">
                        <Typography lineHeight={1} variant='subtitle1' fontWeight={700} color="primary">
                            {/* $29.00 */}
                            </Typography>

                            <CustomStyles.CartWrapper>
                            <CustomStyles.CartContainer>
                            <ShoppingCartIcon 
                            fontSize="medium" 
                            sx={{color: "#fff"}}
                            />
                            </CustomStyles.CartContainer>
                            <AddCircleRoundedIcon 
                            fontSize='small' 
                            sx={{
                            position: "absolute", 
                            bottom: -6, right: -6, 
                            color: "#ff9c33",
                            zIndex: 1,
                        }} 
                            />
                            <CustomStyles.CartBackground />
                            </CustomStyles.CartWrapper>
                    </Grid>

                </CustomStyles.ProductBottomSection>

            </CustomStyles.ProductCard>
            ))}

        </CustomStyles.ProductContainer>

    <Grid container justifyContent="center">
    <CustomStyles.ScrollingButton 
    //onClick={()=>handleSetCategory(item.endpoint)}
    sx={{
        position: "absolute",
        bottom: 25,
        //border: `1px solid ${theme.palette.primary.dark}`,
        color: "#fff",
        //background: "transparent",
        background: theme.palette.primary["dark"],
        "&:hover": {
            background: theme.palette.primary["dark"],
        },
    }}
    variant="contained"
    component={Link}
    to="/products"
    >
        See All
    </CustomStyles.ScrollingButton>
    </Grid>

    </CustomStyles.SubSection>
    </CustomStyles.Section>

    </CustomStyles.SectionWrapper>
  )
}

export default SectionTwo