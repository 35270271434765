import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import Rating from '@mui/material/Rating';
// import Stack from '@mui/material/Stack';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CustomStyles from "../../styles/product/AllProducts";
import { Link } from "react-router-dom";

function AllProducts() {
  const products = [
    {
      name: "Yam flour (2kg)",
      image: "/images/yamflour2kg.png",
    },
    {
      name: "Pepper powder (70g)",
      image: "/images/pepperpowder.png",
    },
    {
      name: "Plantain flour (1kg)",
      image: "/images/plantainflour.png",
    },
    {
      name: "Yam flour (1kg)",
      image: "/images/yamflour1kg.png",
    },
    // Second batch
    {
      name: "Yam flour (5kg)",
      image: "/images/yam-flour-5kg.jpeg",
    },
    {
      name: "Beans flour (500g)",
      image: "/images/beans-flour-500g.jpeg",
    },
    {
      name: "Egusi",
      image: "/images/egusi.jpeg",
    },
    {
      name: "Ogbono",
      image: "/images/ogbono.jpeg",
    },
    {
      name: "Plantain flour (5kg)",
      image: "/images/plantain-flour-5kg.jpeg",
    },
    {
      name: "Pepper powder (70g)",
      image: "/images/pepper-powder-70g.jpeg",
    },
    {
      name: "Yam flour (1kg)",
      image: "/images/yam-flour-1kg.jpeg",
    },
    {
      name: "Yam flour (2kg)",
      image: "/images/yam-flour-2kg.jpeg",
    },
  ];

  return (
    <CustomStyles.SectionWrapper>
      <CustomStyles.Section>
        <Grid container spacing={2}>
          {products.map((item, i) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={i}>
              <CustomStyles.ProductCard
                elevation={5}
                style={{ minWidth: "inherit", maxWidth: "100%", margin: "0px" }}
                component={Link}
                to="/contact-sales"
                className="routerLink"
              >
                <CustomStyles.ProductTopSection>
                  <CustomStyles.ProductImage
                    sx={{ position: "unset", height: "90%", width: "auto" }}
                    src={item.image}
                    alt={item.name}
                  />
                </CustomStyles.ProductTopSection>
                {/* bottom section */}
                <CustomStyles.ProductBottomSection>
                  <div>
                    <Typography variant="subtitle2">{item.name}</Typography>
                    {/* ratings */}
                    {/* <Stack spacing={1}>
                    <Rating name="size-small" defaultValue={4} size="small" readOnly />
                    </Stack> */}
                  </div>
                  {/* Amount area */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Typography
                      lineHeight={1}
                      variant="subtitle1"
                      fontWeight={700}
                      color="primary"
                    >
                      {/* $29.00 */}
                    </Typography>

                    <CustomStyles.CartWrapper>
                      <CustomStyles.CartContainer>
                        <ShoppingCartIcon
                          fontSize="medium"
                          sx={{ color: "#fff" }}
                        />
                      </CustomStyles.CartContainer>
                      <AddCircleRoundedIcon
                        fontSize="small"
                        sx={{
                          position: "absolute",
                          bottom: -6,
                          right: -6,
                          color: "#ff9c33",
                          zIndex: 1,
                        }}
                      />
                      <CustomStyles.CartBackground />
                    </CustomStyles.CartWrapper>
                  </Grid>
                </CustomStyles.ProductBottomSection>
              </CustomStyles.ProductCard>
            </Grid>
          ))}
        </Grid>
      </CustomStyles.Section>
    </CustomStyles.SectionWrapper>
  );
}

export default AllProducts;
