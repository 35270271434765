import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const CustomStyles = {

SectionWrapper: styled("div")(({ theme }) => ({
  padding: "50px 0px",
  background: theme.palette.primary.light100,
  [theme.breakpoints.up("md")]: { 
    padding: "70px 0px",
},
})),

Section: styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: 'center',
  [theme.breakpoints.up("md")]: { 
},
})),

BgText: styled("div")(({theme }) => ({
  display: 'inline-flex',
  alignItems: "center",
  background: theme.palette.primary.light200,
  padding: "3px 10px",
  borderRadius: "50px",
  marginBottom: "8px"
})),

ContainedButton: styled(Button)(({theme }) => ({
  color: "#ffffff",
  textTransform: "capitalize",
  borderRadius: theme.shape["borderRadius"] * 1.5,
})),

OutlinedButton: styled(Button)(({theme }) => ({
  color: theme.palette.primary.main,
  background: theme.palette.primary.light200,
  textTransform: "initial",
  borderRadius: theme.shape["borderRadius"] * 1.5,
})),

ImageContainer: styled(Grid)(({theme }) => ({
  textAlign: 'center',
  padding: 0,
  [theme.breakpoints.up("md")]: { 
    paddingLeft: 20,
  },
})),

Image: styled("img")({
  width: "auto",
  height: "90%",
  objectFit: "contain"
})
}

export default CustomStyles